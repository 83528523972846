h2 {
  margin-bottom: 1em;
  font-size: 20px;
}

.switch {
	position: absolute;
  top: 0;
  left: 25%;
  width: 50%;
  padding: 0.7em 2em 2em 2em;
}

.form {
  width: 100%;
  height: 37em;
  background-color: #f9fcff;
  border-radius: 15px;
  padding-right: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
}

.form-container {
  height: 25em;
  width: 24em;
  padding: 0 2em 2em 2em;
  margin: 0 0 2em 0;
}

.form-group {
  margin: 0.3em 0 0;
  font-size: 12px;
}

.delivery-radio {
  display: flex;
  flex-direction: row
}

.submit-btn {
  background-color: #60B0E1;
  color: #fff;
  margin: 1.3em 0 10em 4em;
  font-size: 15px;
  padding: 1em 2em;
  border-radius: 30px;
}

.note {
  color: gray;
  margin: 8px 0;
}

label {
  margin-left: 0.2em;
}

input, select, textarea {
  height: 2.4em;
  border: none;
  background-color: #ebeaea;
  border-radius: 10px;
  margin:  0.2em 0;
  padding-left: 0.8em;
  font-size: 12px;
}

input.active:valid {
  box-shadow: 0 0 2px 1px green;
  border: 1px green;
}

input.active:invalid {
  box-shadow: 0 0 5px 1px red;
}

textarea {
  width: 100%;
  height: 10em;
  padding: 1em;
}

.validation {
  color: #f43e32;
  font-size: 10px;
  font-weight: bold;
  height: 1px;
  margin-left: 0.2em;
  text-align: right;
}

.vl {
  border-left: 1px solid #ccc;
  height: 25em;
}

.alert-container{
  position: absolute;
  top: 85%;
  left: 85%;
  width: 57em;
  height: 40em;
  transform: translate(-50%, -50%);
}

.alert-inner {
  width: 20em;
  height: 5em;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: #ffffff;
  box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
  -webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
  -moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
}

.delivery-box {
  margin: 0.5em 0 0.3em 0;
  position: relative;
  display: flex;
  border-radius: 10px;
  background-color: #eee;
  box-sizing: border-box;
  padding: 0.3em;
  .radio-btn {
    flex: auto;
    text-align: center;
  }
  input {
    display: none;
  }
  .method {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    padding: 0.7em 0;
  }
  input:checked + .method {
    background-color: #fff;
  }
  label {
    margin: 0;
  }
}

.type {
  background-color: #afd7f0;
}

.q-label {
  margin-top: 1em;
}

.q-box {
  display: flex;
  .add-1 {
    text-align: center;
    margin-left: 9em;
    margin-right: 3em;
    background-color: #f9fcff;
  }
  .method {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 0.4em;
    width: 1.5em;
  }
  label {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  h2 {
    margin-bottom: 1em;
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  ::-webkit-scrollbar-track {
    margin-top: 1em;
    margin-bottom: 1em;
    height: 30em;
  }

  .form {
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-align: start;
    scroll-margin-bottom: 10em;
    padding-right: 0;
  }

  .form-container {
    height: 25em;
    width: 24em;
    padding: 0 2em 2em 2em;
    margin: 0 0 4.5em 0;
  }

  .first {
    margin-top: 5em;
  }

  .consumer {
    margin: 0;
  }

  .no {
    margin: 0;
    margin-bottom: 1em;
  }

  .yes {
    margin: 0;
    margin-bottom: 3em;
  }

  .form-group {
    margin: 0.3em 0 0;
    font-size: 12px;
  }

  .switch {
  	position: absolute;
    top: 0;
    left: 11%;
    width: 30%;
  }

  .vl {
    display: none;
  }

  .alert-container {
    position: absolute;
    cursor: pointer;
    top: 80%;
    left: 59%;
    width: 57em;
    height: 40em;
  }

  .submit-btn {
    margin: 2em 0 3em 4em;
  }
}

@media (min-width: 1200px) {

}

@media (max-width: 500px) {
  .form {
    width: 100%;
    padding: 0 0.5em;
    border-radius: 0;
    margin-top: 0;
  }

  .consumer {
    margin: 0;
    margin-bottom: 2em;
  }

  .no {
    margin: 0;
    margin-bottom: 6.5em;
  }

  .yes {
    margin: 0;
    margin-bottom: 13.3em;
  }

  .switch {
  	position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
  }

  .alert-container {
    left: 66%;
    width: 100%;
  }

  .submit-btn {
    margin: 2em 0 3em 4em;
  }
}
