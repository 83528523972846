@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,300);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

html {
	box-sizing: border-box;
	font-family: 'Open Sans', Helvetica, sans-serif;
}

* {
	box-sizing: inherit;
	padding: 0;
	margin: 0;
}

body {
	overflow: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}

.btn {
    padding: 0.5em 0.7em;
    margin-left: 0.5em;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
}
