.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 9vh;
  width: 100vw;
  background: #1d1e22;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  color: #fff;
  align-items: center;
  padding: 1em 3em 1em;
  margin-bottom: 1em;
  opacity: 0.6;
}

.nav-link {
    color: #fff;
    text-decoration: none;
    margin: 1em 0.5em;
    font-size: 19px;
    font-weight: bold;
}

.nav-link:hover {
  color: #eee;
}

.logo, .logo_s {
  height: 3em;
}

.logo_s {
  display: none;
}

@media only screen and (min-width: 1500px) {
  .nav-link {
      font-size: 22px;
  }
}

@media only screen and (max-width: 500px) {
  .nav-wrapper {
    padding: 1em;
    margin: 0;
  }

  .logo_s {
    display: block;
  }

  .logo {
    display: none;
  }
}
