.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 26em;
  height: 100%;
	transform: translate(-50%, -50%);
  background: #fff;
  color: #333;
	padding: 5em 3em 4em 3.4em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 1.7rem;
    color: #000;
    margin-bottom: 0.4em;
  }

  h3 {
    margin-top: 0.5em;
  }

  p {
    font-size: 13.5px;
    margin: 0.3em;
  }
}

.logo-contrainer {
  background-color: none;
  padding: 1em 0.4em;
  margin: 0.2em 0.8em 2em 0.4em;
  border-radius: 18px;
  display: grid;
  grid-template-columns: repeat(3,2fr);
}

.oidc {
  padding: 0.4em 0.4em;
  grid-template-columns: repeat(2,2fr);
  .eid-btn {
    margin: 0.3em;
    width: 9em;
    height: 3.6em;
    .logo-se {
      height: 3.8em;
    }
    .logo-no {
      width: 6em;
      height: 3.2em;
      margin-top: 0.2em;
    }
    .logo-mitid {
      width: 4.5em;
      height: 2.5em;
      margin-top: 0.6em;
    }
  }
}

.logo-se {
  height: 3.2em;
}

.logo-freja {
  width: 6em;
  height: 1.4em;
  margin-top: 0.8em;
}

.logo-no {
  width: 5em;
  height: 2.8em;
  margin-top: 0.1em;
}

.logo-mitid {
  width: 4em;
  height: 2em;
  margin-top: 0.5em;
}

.logo-idin {
  width: 4em;
  height: 1.9em;
  margin-top: 0.5em;
}

.logo-itsme {
  width: 3.5em;
  height: 1.8em;
  margin-top: 0.5em;
}

.logo-smsotp {
  width: 4em;
  height: 1.8em;
  margin-top: 0.6em;
}

.logo-verimi {
  width: 5em;
  height: 1.1em;
  margin-top: 0.9em;
}

.icon_user {
  width: 7em;
  border-radius: 75px;
  margin-bottom: 0.5em;
}

.finland {
  background: linear-gradient(to bottom, transparent 38%, #003580 38%, #003580 65%, transparent 65%), linear-gradient(to right, transparent 31%, #003580 31%, #003480 42%, transparent 42%), white;
}

.eid-btn {
  display: flex;
  justify-content: center;
  background-color: #f8fbff;
  width: 7em;
  height: 3em;
  margin: 0.2em;
  border-radius: 12px;
  transition: all .3s ease-in-out;

  box-shadow: 0 4px 10px 0 rgba(35, 44, 45, 0.25);
}

.eid-btn:hover{
  box-shadow: 0 0.5px 0.5px 0 rgba(35, 44, 45, 0.25);
}

.eid-btn:active {
  border: 0.7px solid #446381;
}



@media only screen and (min-width: 1500px) {
  .frame {
    p {
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .frame {
    background: none;
    color: #fff;
    h1 {
      font-size: 1.7rem;
      color: #fff;
      margin-bottom: 0.4em;
    }

    p {
      font-size: 13px;
      margin: 0.5em;
    }
  }

  .icon_user {
    width: 6em;
    margin-bottom: 1em;
  }

  .logo-contrainer {
    background: none;
    grid-template-columns: repeat(2,2fr);
  }

  .eid-btn {
    justify-content: center;
    width: 8em;
    height: 3em;
    margin: 0.25em;
    border-radius: 14px;
  }
}
