.home-body {
	height: 109vh;
	width: 100vw;
	font-family: 'Open Sans', Helvetica, sans-serif;
	background-image: url("../../assets/img/Scrive_Background_1.png");
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.text-box {
  text-align: left;
  width: 100%;
  padding-left: 25%;
	color: #fff;
	h1  {
		font-size: 40px;
	}

	h2 {
		font-size: 20px;
	}
}

.home-container {
	position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
}

.login {
	height: 33em;
	margin-top: 2em;
}

.pic-1 {
  width: 50%;
	min-width: 700px;
  border-radius: 20px;
}

@media only screen and (max-width: 500px) {
	.home-container {
	  height: 80%;
		align-items: center;
	}

	.text-box {
	  text-align: left;
	  width: 100%;
	  padding-left: 5%;
		margin-top: 20%;
		color: #fff;
		h1  {
			font-size: 25px;
		}
		h2 {
			font-size: 15px;
			margin-bottom: 0;
		}
	}

	.pic-1 {
		min-width: 100%;
	  border-radius: 0;
		margin-bottom: 30%;
	}
}
