.user-body {
	height: 100vh;
	width: 100vw;
	font-family: 'Open Sans', Helvetica, sans-serif;
	background-image: url("../../assets/img/Scrive_Background.png");
    background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed; /* Add this line */

}

.user-container {
	position: absolute;
	top: 60%;
	left: 50%;
	width: 55em;
	height: 43em;
	transform: translate(-50%, -50%);
}

.user-container-home {
	position: absolute;
	top: 60%;
	left: 50%;
	width: 100%;
	height: 40em;
	transform: translate(-50%, -50%);
}

.flex-container {
	width: 100%;
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
}

.column {
	flex: 25%;
	max-width: 25%;
}

.column-3 {
	max-width: 50%;
	z-index: -1;
	display: flex;
	justify-content: flex-end;
}

.part-container-button {
	background: #fff;
	padding: 2em;
	margin-left: 2em;
	margin-top: 4.3em;
	text-align: center;
	width: 100%;
	border-radius: 8px;
	font-size: 15px;
	cursor: pointer;
}

.part-container-text {
	color: #fff;
	font-size: 36px;
	padding-left: 1em;
	text-align: center;
	width: 100%;
	cursor: default;
}

.user-container-text {
    color: #fff;
	font-size: 40px;
    text-align: center;
    width: 100%;
	position: absolute;
	left: 50%;
	top: 9em;
	transform: translate(-50%, -50%);
	cursor: default;
	p {
		font-size: 20px;
	}
}

.back-div {
	position: absolute;
	padding: 2em 1em;
}

.back-btn {
	color: #fff;
}

.pic-2 {
	width: 40em;
}

@media (min-width: 1500px) {
	.part-container-button {
		font-size: 20px;
		margin-top: 3.5em;
	}

	.part-container-text {
		font-size: 48px;
	}
}

@media (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }

	.column-3 {
		flex: 50%;
		max-width: 100%;
	}

	.pic-2 {
		width: 35em;
	}

	.user-container {
		position: absolute;
	  top: 60%;
	  left: 80%;
	  width: 57em;
		transform: translate(-50%, -50%);
	}

	.part-container-button {
		margin-left: 0.5em;
		margin-top: 3em;
	  width: 80%;
	}

	.part-container-text {
	  color: #fff;
		font-size: 35px;
		padding-left: 2em;
	  text-align: center;
	  width: 100%;
		cursor: default;
	}
}

@media (max-width: 690px) {
	.pic-2 {
		display: none;
	}
}

@media (max-width: 500px) {
	.column {
		flex: 100%;
		max-width: 100%;
		width: 100%;
		justify-content: center;
	}

	.user-container-text {
		h3 {
			font-size: 30px;
		}
	}

	.back-div {
		position: absolute;
		padding: 1.5em 1em;
		width: 100%;
	}

	.user-container-home {
		position: absolute;
		width: 100%;
		margin-top: 15%
	}

	.part-container-button {
		margin-left: 2.4em;
		text-align: center;
	}

	.part-container-text {
		font-size: 25px;
		padding: 0;
	}

	.user-container {
		position: absolute;
		top: 60%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%);
	}

	.form-div {
		height: 100%;
	}

	.form {
		height: 100%;
	}
}
