.success-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success-inner {
  width: 22em;
  height: 15em;
  text-align: center;
  display: flex;
  flex-direction: column;
	align-items: center;
  padding: 2em 1em 1em ;
  background-color: #fff;
  opacity:100%;
  border: 3px #6bc04b solid;

  p {
    font-size: 13px;
		margin: 0.4em 0 0;
		cursor: default;
		white-space: pre-line;
  }
  .success {
    color: #6bc04b;
    margin: 0.5em;
  }
	.success-msg {
    font-weight: bold;
    margin: 0.6em 0 0.3em;
		font-size: 16px;
  }
}

.ok-btn {
	border: 1.5px solid;
  border-color: #6bc04b;
	background-color: #fff;
  opacity: 95%;
  color: #6bc04b;
	width: 12em;
  border-radius: 60px;
  font-weight: bold;
	font-size: 14px;
  margin: 1.5em;
	padding: 0.4em;
	align-items: center;
	cursor: pointer;
}
